var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditLayout",
    {
      attrs: {
        "supports-photo": "",
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        photo: _vm.photo,
      },
      on: {
        submit: function ($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "subtitle" }, slot: "subtitle" },
        [
          _c("BaseInput", {
            attrs: {
              placeholder: `${_vm.$t("enter")} ${_vm.$t("subtitle")} ${_vm.$t(
                "here"
              )}...`,
            },
            model: {
              value: _vm.item.subtitle,
              callback: function ($$v) {
                _vm.$set(_vm.item, "subtitle", $$v)
              },
              expression: "item.subtitle",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
        [
          !_vm.isNew && _vm.item
            ? _c(
                "PreviewLink",
                { attrs: { alias: _vm.item.alias } },
                [_c("BaseButton", [_vm._v(_vm._s(_vm.$t("preview")))])],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "datePicker" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("publishDateAndTime")))]),
              _c("Datetime", {
                attrs: { "first-day-of-week": "1", format: "YYYY/MM/DD H:i:s" },
                model: {
                  value: _vm.publishDate,
                  callback: function ($$v) {
                    _vm.publishDate = $$v
                  },
                  expression: "publishDate",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$tc("author")))]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.user_id,
                    expression: "item.user_id",
                  },
                ],
                staticClass: "form-control",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "user_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.users, function (user) {
                return _c(
                  "option",
                  { key: user.id, domProps: { value: user.id } },
                  [_vm._v(" " + _vm._s(user.username) + " ")]
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            [
              _c("BaseMultiSelect", {
                attrs: {
                  label: _vm.$tc("category", 2),
                  items: _vm.categories,
                  "pre-selected-items": _vm.item.category,
                },
                on: { updateSelected: _vm.updateSelectedCategories },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseMultiSelect", {
                attrs: {
                  label: _vm.$t("relativeConsoles"),
                  items: _vm.consoles,
                  "pre-selected-items": _vm.item.related_consoles,
                },
                on: { updateSelected: _vm.updateSelectedConsoles },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c(
                  "BaseInput",
                  {
                    attrs: {
                      label: _vm.$t("gamesRelated"),
                      "is-slider-checkbox": "",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.game_related,
                          expression: "item.game_related",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "checkbox",
                        "true-value": "1",
                        "false-value": "0",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.item.game_related)
                          ? _vm._i(_vm.item.game_related, null) > -1
                          : _vm._q(_vm.item.game_related, "1"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.item.game_related,
                            $$el = $event.target,
                            $$c = $$el.checked ? "1" : "0"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.item,
                                  "game_related",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.item,
                                  "game_related",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.item, "game_related", $$c)
                          }
                        },
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c(
                  "BaseInput",
                  {
                    attrs: {
                      label: _vm.$t("pcRelated"),
                      "is-slider-checkbox": "",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.pc_related,
                          expression: "item.pc_related",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "checkbox",
                        "true-value": "1",
                        "false-value": "0",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.item.pc_related)
                          ? _vm._i(_vm.item.pc_related, null) > -1
                          : _vm._q(_vm.item.pc_related, "1"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.item.pc_related,
                            $$el = $event.target,
                            $$c = $$el.checked ? "1" : "0"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.item,
                                  "pc_related",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.item,
                                  "pc_related",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.item, "pc_related", $$c)
                          }
                        },
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { attrs: { slot: "sidebar-bottom" }, slot: "sidebar-bottom" }, [
        _c("div", { staticClass: "formType form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("formType")))]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.form_type,
                  expression: "item.form_type",
                },
              ],
              staticClass: "form-control",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.item,
                    "form_type",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(" " + _vm._s(_vm.$t("none")) + " "),
              ]),
              _vm._l(_vm.formTypes, function (formType) {
                return _c(
                  "option",
                  { key: formType, domProps: { value: formType } },
                  [_vm._v(" " + _vm._s(formType) + " ")]
                )
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "metaIndexling form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("metaIndexing")))]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.seo_indexing,
                  expression: "item.seo_indexing",
                },
              ],
              staticClass: "form-control",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.item,
                    "seo_indexing",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(" " + _vm._s(_vm.$t("indexing")) + " "),
              ]),
              _vm._l(_vm.indexing, function (index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: index } },
                  [_vm._v(" " + _vm._s(index) + " ")]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }